import { useSelector } from "react-redux";
import { CartItem, ServiceMethods, StoreDetails, User } from "types";
import { is_time_in_between } from "./helper";
import {
  getDataFromLocalStorage,
  getStringFromLocalStorage,
} from "./localStore";
import { NEW_CUS_COUPON_CODE } from "./constants";
import moment from "moment";

const useCart = () => {
  const service_method = getStringFromLocalStorage(
    "serviceMethod"
  ) as ServiceMethods;
  const cart_slicer = useSelector((state: any) => state.cart);
  const discounted_offer = getDataFromLocalStorage("discounted_offer");
  const promo_code = getDataFromLocalStorage("promo_code");
  const store = getDataFromLocalStorage("store") as StoreDetails;
  const user = getDataFromLocalStorage("user") as User;
  const birthday_coupon = getDataFromLocalStorage("birthday_coupon") as any;

  const get_total_quantity = cart_slicer.cart?.reduce(
    (accumulator: any, item: any) => accumulator + item?.quantity,
    0
  );

  const get_total_deals_quantity = cart_slicer.cart
    ?.filter((deal: CartItem) => deal?.category_code === "offers")
    ?.reduce((accumulator: any, item: any) => accumulator + item?.quantity, 0);

  const total_promo_products_quantity = cart_slicer.cart
    ?.filter((itemx: any) => itemx?.promo_applied)
    ?.reduce((accumulator: any, item: any) => accumulator + item?.quantity, 0);

  const available_items =
    cart_slicer.cart?.length === 0
      ? []
      : cart_slicer.cart?.filter((item: any) => {
          return (
            item?.service_method?.[service_method?.toLowerCase()] &&
            item?.is_sold &&
            item?.stores?.some(
              (itemx: any) => itemx?.store_code === store?.store_code
            )
          );
        });

  const get_total_price = available_items
    ?.reduce((sum: any, item: any) => {
      let total = sum + Number(item?.price) * Number(item?.quantity);
      return total;
    }, 0)
    ?.toFixed(2);

  const get_discounted_deal_actual_price = available_items
    ?.reduce((total: number, item: any) => {
      const sub_total =
        item?.sub_category_code !== "half_half" &&
        item?.category_code !== "offers"
          ? item?.price * item?.quantity
          : 0;
      return total + sub_total;
    }, 0)
    .toFixed(2);

  const get_discounted_deal_price = available_items
    ?.reduce((total: number, item: any) => {
      let sub_total = 0;

      if (
        discounted_offer &&
        item?.category_code !== "offers" &&
        item?.sub_category_code !== "half_half"
      ) {
        sub_total =
          Number(item?.price) * item?.quantity -
          (discounted_offer?.deal_items?.[0]?.discount_type === "fixed_price"
            ? Number(discounted_offer?.deal_items?.[0]?.discount_value) *
              item?.quantity
            : discounted_offer?.deal_items?.[0]?.discount_type === "dollar_off"
            ? Number(item?.price) * item?.quantity -
              Number(discounted_offer?.deal_items?.[0]?.discount_value)
            : discounted_offer?.deal_items?.[0]?.discount_type === "percent_off"
            ? ((Number(item?.price) * item?.quantity) / 100) *
              discounted_offer?.deal_items?.[0]?.discount_value
            : 0);
      } else {
        sub_total = 0;
      }

      return total + sub_total;
    }, 0)
    .toFixed(2);

  const is_product_discount_applied = cart_slicer.cart?.some((item: any) => {
    return item?.discount_applied;
  });

  const redeemed_deals_quantity = cart_slicer.cart
    ?.filter((deal: CartItem) => deal?.is_coupon_redeemed_points)
    ?.reduce((accumulator: any, item: any) => accumulator + item?.quantity, 0);

  const total_redeem_points = cart_slicer.cart
    ?.filter((deal: CartItem) => deal?.is_coupon_redeemed_points)
    ?.reduce(
      (accumulator: number, item: any) =>
        accumulator +
        Number(item?.coupon_redeemed_points || 0) * (item.quantity || 1),
      0
    );

  const get_product_quantity = (id: number) => {
    return (
      cart_slicer.cart?.find((item: CartItem) => item.id === id)?.quantity || 0
    );
  };

  const get_unavailable_cart_items = () => {
    let unavailable_items = [] as any;
    cart_slicer.cart?.some((item: any) => {
      if (
        !item?.service_method?.[service_method?.toLowerCase()] ||
        !item?.is_sold ||
        !item?.stores?.some(
          (itemx: any) => itemx?.store_code === store?.store_code
        )
      ) {
        unavailable_items.push(item);
      }
      return false;
    });
    return unavailable_items;
  };

  const available_in_day = (week_days: any) => {
    try {
      const today = moment().format("ddd").toLowerCase();
      return week_days[today === "thurs" ? "thu" : today] || false;
    } catch (error) {
      console.log(error);
    }
  };

  const product_available = (item: CartItem): boolean => {
    if (!item) return false;

    if (item?.category_code === "offers") {
      if (item?.start_time && item?.end_time) {
        const is_valid = is_time_in_between(item.start_time, item.end_time);
        if (!is_valid) {
          return false;
        }
      }
    }

    const is_service_available =
      item?.service_method && Object.keys(item?.service_method)?.length > 0
        ? item?.service_method[service_method] ?? false
        : true;
    const is_sold = item?.is_sold;

    const is_store_valid =
      item?.stores.length === 0
        ? true
        : item?.stores?.some(
            (store_item: any) => store_item?.store_code === store?.store_code
          ) ?? false;

    return is_service_available && is_sold && is_store_valid;
  };

  const redeem_deal_available = (item: CartItem) => {
    if (
      item?.is_coupon_redeemed_points &&
      Number(user?.loyalty_points) < Number(item?.coupon_redeemed_points)
    ) {
      return false;
    }
    return true;
  };

  const new_cus_coupon: CartItem = cart_slicer.cart?.find((item: CartItem) => {
    return item?.deal_code === NEW_CUS_COUPON_CODE;
  });

  const product_valid_for_promo = (item: CartItem) => {
    return !item?.promo_applied || !product_available(item)
      ? false
      : promo_code?.products?.some((itemx: any) => {
          if (item?.category_code === "pizza") {
            return (
              item?.osg_code === itemx?.osg_code &&
              promo_code?.product_pizza_sizes?.includes(
                item?.products?.[0]?.size_code
              ) &&
              promo_code?.product_sub_category?.includes(
                item?.sub_category_code
              )
            );
          } else if (item?.category_code === "drinks") {
            return (
              promo_code?.product_other_sizes?.includes(
                item?.products?.[0]?.size_code
              ) && item?.product_code === itemx?.product_code
            );
          } else {
            return item?.product_code === itemx?.product_code;
          }
        });
  };

  const product_valid_for_bd_coupon = (cart_item: any) => {
    let is_available = false;

    if (promo_code) return false;

    birthday_coupon?.allowed_items?.forEach((item: any) => {
      if (
        cart_item?.category_code === "pizza" &&
        (cart_item?.osg_code === item?.osg_code ||
          (cart_item?.osg_code === "_PIZZA" && item?.osg_code === "PIZZA") ||
          (cart_item?.osg_code === "HALFPIZZA" &&
            cart_item?.products?.[0]?.osg_code === item?.osg_code &&
            cart_item?.products?.[1]?.osg_code === item?.osg_code)) &&
        item?.size_code === "any"
      ) {
        is_available = true;
      } else if (
        cart_item?.category_code === "pizza" &&
        (item?.size_code === cart_item?.products?.[0]?.size_code ||
          item?.size_code === cart_item?.size_code) &&
        (cart_item?.osg_code === item?.osg_code ||
          (cart_item?.osg_code === "_PIZZA" && item?.osg_code === "PIZZA") ||
          (cart_item?.osg_code === "HALFPIZZA" &&
            cart_item?.products?.[0]?.osg_code === item?.osg_code &&
            cart_item?.products?.[1]?.osg_code === item?.osg_code))
      ) {
        is_available = true;
      } else if (
        cart_item?.category_code === "drinks" &&
        item?.size_code === "all_drinks" &&
        cart_item?.product_code === item?.product_code
      ) {
        is_available = true;
      } else if (
        cart_item?.category_code === "drinks" &&
        (item?.size_code === cart_item?.products?.[0]?.size_code ||
          item?.size_code === cart_item?.size_code) &&
        cart_item?.product_code === item?.product_code
      ) {
        is_available = true;
      } else if (
        cart_item?.category_code !== "pizza" &&
        cart_item?.product_code === item?.product_code
      ) {
        is_available = true;
      }
    });
    return is_available;
  };

  const item_cart_price = (item: CartItem): number => {
    try {
      let total = item?.price || item?.product_price || (0 as any);

      let discount_type: string | undefined;
      let discount_value: number | undefined;

      if (promo_code) {
        ({ discount_type, discount_value } = promo_code);
      } else if (discounted_offer) {
        const deal_item = discounted_offer?.deal_items?.[0];
        ({ discount_type, discount_value } = deal_item || {});
      } else if (
        (item?.discount_applied || item?.is_discount_available) &&
        !promo_code &&
        !birthday_coupon
      ) {
        discount_type = item?.product_discount_type;
        discount_value = item?.product_discount;
      } else if (birthday_coupon) {
        ({ discount_type, discount_value } = birthday_coupon);
      }

      const is_valid_promo =
        promo_code?.promo_type === "product_specific" &&
        product_valid_for_promo(item);
      const is_eligible_for_discount =
        item?.is_discount_available ||
        (item?.discount_applied && !promo_code && !birthday_coupon) ||
        (discounted_offer && item?.category_code !== "offers") ||
        is_valid_promo ||
        (birthday_coupon &&
          item?.category_code !== "offers" &&
          product_valid_for_bd_coupon(item));

      if (
        is_eligible_for_discount &&
        discount_type &&
        discount_value !== undefined
      ) {
        switch (discount_type) {
          case "fixed_price":
            total = discount_value;
            break;
          case "dollar_off":
            total -= discount_value;
            break;
          case "percent_off":
            total -= total * (discount_value / 100);
            break;
        }
      }

      return Math.max(Number(total), 0);
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  const promo_price_before_discount = () => {
    let total;

    if (
      promo_code?.allowed_other_coupon &&
      !promo_code?.allow_coupons_discounted_price
    ) {
      total = available_items.reduce(
        (sum: any, item: any) =>
          sum +
          (item?.category_code === "offers"
            ? item?.actual_price
            : item?.price) *
            item?.quantity,
        0
      );
    } else {
      total = available_items.reduce(
        (sum: any, item: any) => sum + item?.price * item?.quantity,
        0
      );
    }
    return total?.toFixed(2);
  };

  const get_promo_price = () => {
    try {
      let total;
      if (promo_code?.promo_type === "product_specific") {
        if (
          promo_code?.allowed_other_coupon &&
          promo_code?.allow_coupons_discounted_price
        ) {
          total = available_items?.reduce((sum: any, item: any) => {
            return (
              sum +
              (item?.promo_applied ? item_cart_price(item) : item?.price) *
                item?.quantity
            );
          }, 0);
        } else {
          total = available_items?.reduce((sum: any, item: any) => {
            return (
              sum +
              Number(
                (item?.promo_applied ? item_cart_price(item) : item?.price) *
                  item?.quantity
              )
            );
          }, 0);
        }
      } else {
        total = get_total_price;
      }

      if (promo_code?.promo_type === "entire_order") {
        const { discount_type, discount_value } = promo_code;

        switch (discount_type) {
          case "fixed_price":
            total = discount_value;
            break;

          case "dollar_off":
            total = get_total_price - discount_value;
            break;

          case "percent_off":
            total = get_total_price - get_total_price * (discount_value / 100);
            break;

          default:
            total = get_total_price;
        }
      }

      return Number(Math.max(total, 0))?.toFixed(2);
    } catch (error) {
      console.log(error);
    }
  };

  const unavailable_promo_products = () => {
    const promo_products = promo_code?.products?.map((item: any) => {
      return item?.osg_code || item?.product_code;
    });

    if (promo_code?.promo_type === "entire_order") {
      return cart_slicer.cart
        ?.map((item: any) => {
          const filteredProducts = item?.products?.filter((product: any) => {
            if (item?.category_code === "pizza") {
              return (
                promo_products?.includes(product?.osg_code) &&
                promo_code?.product_pizza_sizes?.includes(product?.size_code) &&
                promo_code?.product_sub_category?.includes(
                  product?.sub_category_code
                )
              );
            } else if (item?.category_code === "drinks") {
              return (
                promo_code?.product_other_sizes?.includes(product?.size_code) &&
                promo_products?.includes(product?.product_code)
              );
            } else {
              return promo_products?.includes(product?.product_code);
            }
          });

          if (filteredProducts.length > 0) {
            if (item?.sub_category_code === "half_half") {
              return { ...filteredProducts[0] };
            } else {
              return {
                ...filteredProducts[0],
                en_content: item?.en_content,
                ge_content: item?.ge_content,
                product_name: item?.product_name,
              };
            }
          }

          return null;
        })
        .filter(Boolean)[0];
    } else {
      return null;
    }
  };

  const is_deal_in_cart = cart_slicer.cart?.some(
    (item: CartItem) => item?.category_code === "offers"
  );

  const is_coupon_applied = promo_code || discounted_offer || birthday_coupon;

  const first_order_deal_in_cart = cart_slicer.cart?.some((item: CartItem) => {
    return item?.deal_code === NEW_CUS_COUPON_CODE;
  });

  const bd_coupon_discount_price = available_items
    ?.reduce((total: number, item: any) => {
      let sub_total = 0;
      sub_total =
        birthday_coupon &&
        item?.sub_category_code !== "half_half" &&
        item?.category_code !== "offers"
          ? (product_valid_for_bd_coupon(item) ? item_cart_price(item) : 0) *
            item?.quantity
          : 0;
      return total + sub_total;
    }, 0)
    .toFixed(2);

  const bd_coupon_actual_price = available_items
    ?.reduce((total: number, item: any) => {
      const sub_total =
        item?.sub_category_code !== "half_half" &&
        item?.category_code !== "offers"
          ? (product_valid_for_bd_coupon(item) ? item?.price : 0) *
            item?.quantity
          : 0;
      return total + sub_total;
    }, 0)
    .toFixed(2);

  const bd_coupon_products_quantity = cart_slicer.cart
    ?.filter((item: CartItem) => product_valid_for_bd_coupon(item))
    ?.reduce((accumulator: any, item: any) => accumulator + item?.quantity, 0);

  const show_discount = (item: CartItem) => {
    return (
      item?.category_code !== "offers" &&
      ((item?.discount_applied && !promo_code && !birthday_coupon) ||
        discounted_offer ||
        (birthday_coupon && product_valid_for_bd_coupon(item)) ||
        (promo_code?.promo_type === "product_specific" &&
          product_valid_for_promo(item)))
    );
  };

  const not_allowed_product_promo = unavailable_promo_products() as any;

  const get_discount_value = () => {
    let discount = 0;
    let total = available_items?.reduce(
      (sum: any, item: any) => sum + item_cart_price(item) * item?.quantity,
      0
    );
    if (promo_code) {
      discount =
        Number(promo_price_before_discount()) - Number(get_promo_price());
    } else {
      discount = Number(get_total_price) - Number(total);
    }
    return Number(Math.max(discount, 0))?.toFixed(2);
  };

  const pizza_size_price = (item: CartItem, size: string) => {
    try {
      let price = item?.product_sizes?.find((itemx: any) => {
        return itemx?.size_code?.toLowerCase() === size?.toLowerCase();
      })?.product_price;
      return Number(price)?.toFixed(2);
    } catch (error) {
      console.log(error);
    }
  };

  const is_redeem_deal_in_cart = cart_slicer.cart?.some((item: CartItem) => {
    return item?.category_code === "offers" && item?.is_coupon_redeemed_points;
  });

  const discount_value = get_discount_value();

  const get_subtotal = () => {
    let sub_total = 0 as any;
    let total = available_items?.reduce(
      (sum: any, item: CartItem) =>
        sum + item_cart_price(item) * item?.quantity,
      0
    );

    if (promo_code && Number(discount_value) > 0) {
      sub_total = get_promo_price();
    } else {
      sub_total = total;
    }

    return Number(Math.max(sub_total, 0))?.toFixed(2);
  };

  const sub_total = get_subtotal();

  const total_loyality_points =
    cart_slicer.cart
      ?.filter((itemx: CartItem) => itemx?.loyalty_point_allowed)
      ?.reduce(
        (accumulator: any, item: CartItem) => accumulator + item?.quantity,
        0
      ) * 100;

  const promo_valid = !promo_code
    ? false
    : !(
        Number(promo_price_before_discount()) >
          Number(promo_code?.max_order_price) ||
        Number(get_total_price) < Number(promo_code?.min_order_price) ||
        (is_deal_in_cart && !promo_code?.allowed_other_coupon) ||
        (not_allowed_product_promo &&
          promo_code?.promo_type === "entire_order") ||
        (promo_code?.promo_type === "product_specific" &&
          !promo_code?.allowed_max_qty &&
          total_promo_products_quantity > promo_code?.max_product_qty)
      );

  return {
    get_total_quantity,
    get_total_price,
    get_discounted_deal_actual_price,
    get_discounted_deal_price,
    promo_valid,
    get_product_quantity,
    get_unavailable_cart_items,
    product_available,
    item_cart_price,
    product_valid_for_promo,
    total_promo_products_quantity,
    not_allowed_product_promo,
    promo_price_before_discount,
    is_deal_in_cart,
    discount_value,
    is_product_discount_applied,
    sub_total,
    is_coupon_applied,
    redeemed_deals_quantity,
    total_redeem_points,
    show_discount,
    pizza_size_price,
    get_total_deals_quantity,
    total_loyality_points,
    is_redeem_deal_in_cart,
    redeem_deal_available,
    first_order_deal_in_cart,
    new_cus_coupon,
    available_in_day,
    product_valid_for_bd_coupon,
    bd_coupon_discount_price,
    bd_coupon_actual_price,
    bd_coupon_products_quantity,
  };
};

export default useCart;
