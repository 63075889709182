import { lazy } from "react";
import Loadable from "ui-components/Loadable";

export const Home = Loadable(lazy(() => import("views/Home")));
export const Welcome = Loadable(lazy(() => import("views/Welcome")));
export const PhoneVerify = Loadable(lazy(() => import("views/PhoneVerify")));
export const ServiceMethod = Loadable(lazy(() => import("views/ServiceMethod")));
export const OTPVerification = Loadable(lazy(() => import("views/OtpVerify")));
export const SignUpDetails = Loadable(lazy(() => import("views/SignupDetails")));
export const Addressess = Loadable(lazy(() => import("views/Addresses")));
export const AddAddress = Loadable(lazy(() => import("views/AddAddress")));
export const AddressDetails = Loadable(lazy(() => import("views/AddressDetails")));
export const ChooseStore = Loadable(lazy(() => import("views/ChooseStore")));
export const ForgotPassword = Loadable(lazy(() => import("views/ForgotPassword")));
export const JoinUsForm = Loadable(lazy(() => import("views/JoinUsForm")));

export const Offers = Loadable(lazy(() => import("views/Offers")));
export const OfferDetail = Loadable(lazy(() => import("views/Offers/offer_detail")));
export const Pizza = Loadable(lazy(() => import("views/Pizza")));
export const PizzaDetails = Loadable(lazy(() => import("views/PizzaDetails")));
export const Bread = Loadable(lazy(() => import("views/Bread")));
export const Chicken = Loadable(lazy(() => import("views/Chicken")));
export const Sandwich = Loadable(lazy(() => import("views/Sandwich")));
export const Desserts = Loadable(lazy(() => import("views/Dessert")));
export const Drinks = Loadable(lazy(() => import("views/Drink")));
export const Wrap = Loadable(lazy(() => import("views/Wrap")));
export const LoadPotato = Loadable(lazy(() => import("views/LoadPotato")));
export const Sauce = Loadable(lazy(() => import("views/Sauce")));

export const Stores = Loadable(lazy(() => import("views/Stores")));
export const Cart = Loadable(lazy(() => import("views/Cart")));
export const OrderDetail = Loadable(lazy(() => import("views/OrderDetail")));
export const AddCard = Loadable(lazy(() => import("views/AddCard")));
export const OrderConfirmation = Loadable(lazy(() => import("views/OrderConfirmation")));
export const UserProfile = Loadable(lazy(() => import("views/UserProfile")));
export const Thankyou = Loadable(lazy(() => import("views/Thankyou")));
export const UserPoints = Loadable(lazy(() => import("views/UserPoints")));
export const Favorite = Loadable(lazy(() => import("views/Favorite")));
export const OrderHistory = Loadable(lazy(() => import("views/OrderHistory")));
export const Tracking = Loadable(lazy(() => import("views/OrderTracking")));
export const Tracker = Loadable(lazy(() => import("views/Tracker")));
export const SingleOrderDetails = Loadable(lazy(() => import("views/SingleOrderDetail")));
export const Feedback = Loadable(lazy(() => import("views/Feedback")));
export const Login = Loadable(lazy(() => import("views/Login")));
export const PrivacyPolicy = Loadable(lazy(() => import("views/PrivacyPolicy")));
export const DeliveryPolicy = Loadable(lazy(() => import("views/DeliveryPolicy")));
export const ReturnPolicy = Loadable(lazy(() => import("views/ReturnPolicy")));
export const TermsAndConditions = Loadable(lazy(() => import("views/TermsAndConditions")));
export const About = Loadable(lazy(() => import("views/About")));
export const HygieneCertificate = Loadable(lazy(() => import("views/HygieneCertificate")));
export const JoinOurTeam = Loadable(lazy(() => import("views/JoinOurTeam")));
export const HalfnHalfDetails = Loadable(lazy(() => import("views/Half'HalfDetails")));
export const NotFound = Loadable(lazy(() => import("views/NotFound")));


