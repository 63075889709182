import CryptoJS from "crypto-js";
import { _secretKey } from "./helper";

interface Data {
  [key: string]: any;
}

const encrypt = (data: string, key: string) => {
  const ciphertext = CryptoJS.AES.encrypt(data, _secretKey).toString();
  return ciphertext;
};

const decrypt = (ciphertext: string) => {
  try {
    const bytes = CryptoJS.AES.decrypt(ciphertext, _secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData ? JSON.parse(decryptedData) : null;
  } catch (error) {
    console.error("Decryption error:", error);
    return null;
  }
};

const decrypt_string = (ciphertext: string) => {
  try {
    const bytes = CryptoJS.AES.decrypt(ciphertext, _secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  } catch (error) {
    console.error("Decryption error:", error);
    return null;
  }
};

export const saveStringToLocalStorage = (key: string, data: string): void => {
  try {
    const encryptedData = encrypt(data, _secretKey);
    localStorage.setItem(key, encryptedData);
  } catch (error) {
    console.log("Error saving string to local storage: " + error);
  }
};

export const saveDataToLocalStorage = (key: string, data: Data): void => {
  try {
    const encryptedData = encrypt(JSON.stringify(data), _secretKey);
    localStorage.setItem(key, encryptedData);
  } catch (error) {
    console.log("Error saving data to local storage:" + error);
  }
};

export const getStringFromLocalStorage = (key: string): string | null | undefined => {
  try {
    const storedData = localStorage.getItem(key);
    if (storedData) {
      return decrypt_string(storedData);
    }
    return null;
  } catch (error) {
    console.log("Error getting string from local storage: " + error);
    return undefined;
  }
};

export const getDataFromLocalStorage = (key: string): Data | null | undefined => {
  try {
    const storedData = localStorage.getItem(key);
    if (storedData) {
      return decrypt(storedData);
    }
    return null;
  } catch (error) {
    console.log("Error getting data from local storage: " + error);
    return undefined;
  }
};

export const clearDataFromLocalStorage = (key: string): void => {
  localStorage.removeItem(key);
};
