import "./App.css";

import { useSelector } from "react-redux";

import {
  CssBaseline,
  StyledEngineProvider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

// routing
import NavigationScroll from "layout/NavigationScroll";
import AppRoute from "routes";

// defaultTheme
import { Bounce, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import themes from "themes";
import useDisableContextMenu from "hooks/useDisableContextMenu";

function App() {
  const theme = useTheme() as any;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const customization = useSelector((state: any) => state.customization);
  useDisableContextMenu();
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          limit={1}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          transition={isMobile ? Slide : Bounce}
          theme={isMobile ? "light" : "colored"}
          style={
            isMobile
              ? {
                  width: 230,
                  borderRadius: 52,
                  fontSize: 12,
                  marginTop: 25,
                  marginLeft: 130,
                  zIndex: 999999,
                }
              : { zIndex: 999999 }
          }
        />
        <NavigationScroll>
          <CssBaseline />
          <AppRoute />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
