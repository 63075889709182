import { useTheme } from "@emotion/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import { Outlet } from "react-router";
import { useLocation } from "react-router-dom";
import Categories from "ui-components/Categories";
import { IsAuthenticatedRoute } from "utils/helper";
import { getDataFromLocalStorage } from "utils/localStore";
import Header from "./Header";
import Loadable from "ui-components/Loadable";
import { lazy } from "react";

const Footer = Loadable(lazy(() => import("./Footer")));
const BottomNav = Loadable(lazy(() => import("./navBarBottom")));

export default function MainLayout({ classes }: { classes?: any }) {
  const location = useLocation();
  const theme = useTheme() as any;
  const store = getDataFromLocalStorage("store");
  const matchesDownMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Box sx={{ minHeight: matchesDownMd ? "" : "80vh" }}>
        <Header classes={classes} />

        {!IsAuthenticatedRoute() && location.pathname !== "/" && store ? (
          <Categories />
        ) : null}

        <Outlet />
      </Box>

      {matchesDownMd && !IsAuthenticatedRoute() && store ? (
        <BottomNav classes={classes} />
      ) : null}

      {!matchesDownMd || IsAuthenticatedRoute() ? <Footer /> : null}
    </>
  );
}
