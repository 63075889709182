/*
  Created By Muhammad Hassaan
  Date 10 April 2023
*/
import { makeStyles } from "@mui/styles";
import { defaultLabelColors } from "utils/constants";
const useStyles = makeStyles((theme: any) => ({
  main_layout_padding: {
    padding: "30px 75px 20px 75px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 10px 20px 10px",
      marginBottom: "60px",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "20px 10px 20px 10px",
      marginBottom: "60px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "20px 55px 20px 55px",
      marginBottom: "60px",
    },
  },

  header_chip: {
    width: 150,
    height: 47,
    borderRadius: 28,
    alignItems: "center",
    fontWeight: "bold",
  },
  header_chip_avatar: {
    objectFit: "contain",
    borderRadius: 0,
    marginBottom: 0.7,
    background: "none",
  },

  input_feild: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    // background: "#fff",
    color: theme.palette.dominos.black,
    "&::placeholder": {
      color: "#000",
      fontSize: 16,
      // fontWeight: 600,
      lineHeight: 24,
      letterSpacing: 0.3,
      fontFamily: "Kanit-Light",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      "&::placeholder": {
        fontSize: 16,
      },
    },
  },

  rounded_input_feild: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    borderRadius: 30,
    color: theme.palette.dominos.black,
    "&::placeholder": {
      color: "#000",
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 24,
      letterSpacing: 0.3,
      fontFamily: "Kanit-Light",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      "&::placeholder": {
        fontSize: 16,
        fontFamily: "Kanit-SemiBold",
      },
    },
  },

  input_label_style: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#000",
  },

  blue_rounded_button: {
    background: theme.palette.dominos.appSecondary,
    color: theme.palette.dominos.sand,
    transition: "1s",
    borderRadius: 30,
    paddingInline: 30,
    border: "none",
    "&:hover": {
      background: theme.palette.dominos.appSecondary,
      color: theme.palette.dominos.sand,
      transition: "1s",
      border: "none",
      boxShadow:
        "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
    "&:disabled": {
      background: "#AEAEAE",
      color: "white",
    },
  },
  red_rounded_button: {
    background: theme.palette.dominos.appPrimary,
    color: theme.palette.dominos.sand,
    transition: "1s",
    borderRadius: 30,
    paddingInline: 30,
    border: "none",
    "&:hover": {
      background: theme.palette.dominos.appPrimary,
      color: theme.palette.dominos.sand,
      transition: "1s",
      border: "none",
      boxShadow:
        "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
    "&:disabled": {
      background: "#AEAEAE",
      color: theme.palette.dominos.sand,
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: 10,
    },
  },

  edit_icon: {
    color: "black",
    fontSize: 25,
    transition: ".3s",
    cursor: "pointer",
    "&:hover": { color: "#0078AE" },
  },
  delete_icon: {
    fontSize: 25,
    color: "black",
    transition: ".3s",
    cursor: "pointer",
    "&:hover": { color: "#E51937" },
  },
  modal_title: {
    fontSize: 22,
    fontWeight: "normal",
    lineHeight: "normal",
    color: defaultLabelColors.color_h,
    letterSpacing: "0.015em",
  },

  btn_white: {
    color: "#000000",
    background: theme.palette.dominos.sand,
    borderRadius: 25,
    padding: "4px 44px",
    whiteSpace: "nowrap",
    textTransform: "capitalize",
    border: `1px solid #000000 !important`,
    boxShadow: "0px 0px 3px black",
    letterSpacing: 0.3,
    "&:hover": {
      background: theme.palette.dominos.sand,
      color: "#000000",
    },
  },

  btn_blue: {
    color: theme.palette.dominos.sand,
    background: theme.palette.dominos.appSecondary,
    borderRadius: 25,
    padding: "4px 44px",
    whiteSpace: "nowrap",
    textTransform: "capitalize",
    border: `1px solid ${theme.palette.dominos.appSecondary} !important`,
    boxShadow: "0px 0px 3px black",
    letterSpacing: 0.3,
    "&:hover": {
      background: theme.palette.dominos.appSecondary,
      color: theme.palette.dominos.sand,
    },
  },

  // main styling

  home_page_modal_content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    gap: "2rem",
    marginTop: "1rem",
    marginBottom: "1rem",
    textAlign: "center",
  },
  home_page_modal_content_img: {
    background: "#E6233F",
    display: "flex",
    justifyContent: "center",
    width: 160,
    borderRadius: 12,
  },

  login_banner_inner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 100px)",
  },
  welcome_screen: {
    textAlign: "center",
  },
  welcome_buttons: {
    display: "flex",
    flexDirection: "column",
    gap: "18px",
    margin: "12px 0px 28px 0px",
    flexWrap: "wrap",
    justifyContent: "center",
    "& .button_one": {
      width: 420,
      height: 57,
      background: "#0078AD",
      borderRadius: 20,
      fontSize: 22,
      textTransform: "capitalize",
    },
    "& .button_two": {
      width: 420,
      height: 57,
      background: "white",
      borderRadius: 20,
      fontSize: 22,
      textTransform: "capitalize",
      color: theme.palette.dominos.appSecondary,
    },
  },

  login_modal: {
    maxHeight: 388,
    maxWidth: 665,
    width: 665,
    height: 388,
    background: "white",
    borderRadius: "0px 0px 28px 28px",
    position: "relative",
    boxShadow: "0px 0px 3px gray",
    [theme.breakpoints.down("md")]: {
      width: 550,
      height: 400,
    },
  },

  login_modal_buttons: {
    position: "absolute",
    display: "flex",
    width: "100%",
    top: "-53px",
  },
  modal_button: {
    padding: "16px 0px",
    border: "none",
    fontWeight: 600,
    fontSize: 14,
    position: "relative",
    cursor: "pointer",
    letterSpacing: 0.3,
  },
  login_modal_content: {
    margin: "0 auto",
  },
  login_button: {
    background: theme.palette.dominos.appSecondary,
    borderRadius: 25,
    textTransform: "capitalize",
    padding: 8,
  },
  otp_inputs: {
    marginRight: 12,
    borderRadius: 8,
    padding: 12,
    textAlign: "center",
    border: "2px solid #0078AE",
    color: "black",
    width: "60px",
    height: "55px",
    marginBottom: 12,
  },

  delivery_type: {
    display: "flex",
    gap: 24,
    alignItems: "center",
    "& .button_one": {
      width: 200,
      height: 44,
      color: theme.palette.dominos.appPrimary,
      background: theme.palette.dominos.sand,
      borderRadius: 30,
      border: `2px solid ${theme.palette.dominos.appPrimary} !important`,
      textTransform: "uppercase",
      cursor: "pointer",
    },
    "& .button_two": {
      width: 200,
      height: 44,
      color: theme.palette.dominos.appSecondary,
      background: theme.palette.dominos.sand,
      borderRadius: 30,
      border: `2px solid ${theme.palette.dominos.appSecondary} !important`,
      textTransform: "uppercase",
      cursor: "pointer",
    },
    [theme.breakpoints.down("sm")]: {
      height: "100dvh",
    },
  },
  delivery_type_card_one: {
    width: 200,
    height: 126,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    outline: "3px solid white",
    borderRadius: 30,
    background: theme.palette.dominos.appPrimary,
    marginBottom: 18,
  },
  delivery_type_card_two: {
    width: 200,
    height: 126,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    outline: "3px solid white",
    borderRadius: 30,
    background: theme.palette.dominos.appSecondary,
    marginBottom: 18,
  },

  // addresses styling start
  change_address_pill: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 25,
    border: "1px solid black",
    borderRadius: 25,
    padding: "4px 18px",
    overflow: "hidden",
    "& > div:first-child": {
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
    },
    "& > p": {
      whiteSpace: "nowrap",
      color: "#5A5555",
      maxWidth: 500,
    },
    "& > button": {
      border: "1px solid #0078AD",
      textTransform: "capitalize",
      borderRadius: 25,
      background: "#fff",
      padding: "0px 24px",
      marginLeft: 6,
    },
  },
  addresses_pill: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 30,
    padding: "4px 24px",
    boxShadow: "0px 3px 4px 2px lightgrey",
    marginBottom: 15,
    cursor: "pointer",
    marginInline: 4,
    userSelect: "none",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 15,
    },
  },
  // addresses styling end

  // products and offers card styling start
  product_grid: {
    display: "grid",
    gridTemplateColumns: "repeat(6, 265px)",
    gap: 20,
    "@media (max-width: 1800px)": {
      gridTemplateColumns: "repeat(5, 1fr)",
    },
    "@media (max-width: 1430px)": {
      gridTemplateColumns: "repeat(4, 1fr)",
    },
    "@media (max-width: 1200px)": {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    "@media (max-width: 900px)": {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    "@media (max-width: 600px)": {
      gridTemplateColumns: "repeat(1, 100%)",
      padding: 10,
      gap: 20,
    },
    "@media (max-width: 400px)": {
      gridTemplateColumns: "repeat(1, 100%)",
      padding: 10,
      gap: 20,
    },
  },

  offer_card: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    gap: 20,
    "@media (max-width: 1800px)": {
      gridTemplateColumns: "repeat(4, 265px)",
    },
    "@media (max-width: 1430px)": {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    "@media (max-width: 1200px)": {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    "@media (max-width: 900px)": {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    "@media (max-width: 600px)": {
      gridTemplateColumns: "repeat(1, 100%)",
    },
    "@media (max-width: 400px)": {
      gridTemplateColumns: "repeat(1, 100%)",
      padding: 10,
      gap: 20,
    },
  },

  product_card: {
    background: "white",
    borderRadius: 11,
    overflow: "hidden",
    position: "relative",
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 9px 0px;",
    [theme.breakpoints.down("sm")]: {
      marginTop: 2,
      overflow: "visible",
    },
  },

  product_not_available: {
    position: "absolute",
    background: "#0000007d",
    inset: -12,
    bottom: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderRadius: "11px 11px 0px 0px",
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      background: "#0000007d",
      bottom: 0,
      top: 0,
      left: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      borderRadius: "11px 11px 11px 11px",
    },
  },

  product_card_inner: {
    padding: 12,
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      padding: " 10px 10px",
    },
  },
  product_favorite_icon: {
    textAlign: "end",
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      right: 6,
      top: 4,
    },
  },
  product_name: {
    color: "#000000",
    fontSize: 18,
    textTransform: "capitalize",
    letterSpacing: 0.3,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
      fontSize: 16,
    },
  },
  product_desc: {
    fontSize: 12,
    color: "#000000",
    textTransform: "capitalize",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "85%",
      fontSize: 12,
    },
  },
  product_price: {
    // color: "#E31836",
    fontSize: 18,
  },
  offer_product_price: {
    color: "#E31836",
    fontSize: 40,
  },
  order_card_footer: {
    alignItems: "center",
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    marginTop: 16,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      gridTemplateColumns: "7fr 2fr",
    },
  },
  cart_btn: {
    background: "#E80045",
    width: 65,
    height: 25,
    color: "#ffffff",
    borderRadius: 8,
    border: "none",
    fontSize: 14,
    letterSpacing: 0.3,
    cursor: "pointer",
    textTransform: "uppercase",
    marginLeft: "auto",
    userSelect: "none",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: "85px",
      fontSize: 14,
    },
  },

  // products and offers card styling end
  offer_filter: {
    fontSize: "24px",
    lineHeight: "24px",
    letterSpacing: "0.3px",
    paddingLeft: "20px",
  },

  filter_radio_label: {
    fontSize: "16px",
    letterSpacing: "0.3px",
    lineHeight: "24px",
    paddingTop: 26,
    color: "#454444",
    paddingBottom: 10,
  },
  filter_divider: {
    color: "#D9D9D9",
    marginTop: "10px",
    marginBottom: "25px",
    marginRight: "20px",
  },
  price_labels: {
    color: "#454444",
    fontSize: "12px",
    fontWeight: 600,
  },

  // offers details page styling start
  offer_detail_discount: {
    color: "#aeaeae",
    fontSize: "28px",
    fontWeight: "bold",
    textDecoration: "line-through",
    [theme.breakpoints.down("lg")]: {
      fontSize: "18px",
    },
  },
  offer_detail_deal_card: {
    borderRadius: 35,
    backgroundColor: "#ffffff",
    boxShadow: "0 .5rem 1rem rgba(0,0,0,.15)",
    [theme.breakpoints.down("sm")]: {
      borderRadius: 15,
    },
  },
  offer_detail_deal_heading: {
    color: "#000000",
    fontSize: "23px",
    padding: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  offer_detail_deal_content: {
    width: "100%",
    border: "1px solid #454444",
    borderRadius: 21,
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  offer_detail_deal_content_field: {
    width: "100%",
    border: "1px solid #454444",
    borderRadius: 21,
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      alignItems: "center",
    },
  },

  offer_detail_deal_image: {
    width: 160,
    height: 100,
    marginLeft: 20,
    objectFit: "contain",
    paddingBlock: "10px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 5,
      marginLeft: 8,
      paddingBottom: 5,
      width: 100,
      height: "80px",
      paddingBlock: "10px",
    },
  },
  offer_detail_deal_item: {
    fontSize: "20px",
    color: "#000000",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 5,
      fontSize: "16px",
    },
  },
  offer_detail_deal_description: {
    fontSize: "15px",
    color: "#454444",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  offer_card_main: {
    height: "100%",
    borderRadius: 11,
    backgroundColor: "#ffffff",
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      width: "100%",
      display: "flex",
    },
  },
  // offers details page styling end

  sub_category: {
    width: "100%",
    paddingBlock: 10,
    background: theme.palette.dominos.appPrimary,
    borderRadius: 25,
    marginTop: 15,
    overFlow: "auto",
  },
  sub_category_inner: {
    width: "75%",
    margin: "0 auto",
    display: "flex",
    gap: 42,
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
      gap: 10,
      margin: "0",
      paddingInline: 20,
    },
  },
  sub_category_name: {
    cursor: "pointer",
    fontSize: 20,
    color: theme.palette.dominos.sand,
    whiteSpace: "nowrap",
  },

  // cart page styling start

  cart_header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 30,
    [theme.breakpoints.down("lg")]: {
      textAlign: "center",
      width: "auto",
      flexDirection: "column",
      marginTop: 0,
    },
  },
  cart_steps: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > div": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: 8,
      width: 210,
      [theme.breakpoints.down("lg")]: {
        width: 150,
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: 140,
      marginTop: 14,
      gap: 30,
    },
  },
  cart_steps_dots: {
    width: 61,
    height: 61,
    borderRadius: 100,
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 7,
      width: 55,
      height: 55,
    },
  },

  // cart page styling end

  //order details page
  order_detail_card: {
    padding: 20,
    paddingBottom: 6,
    [theme.breakpoints.down("md")]: {
      padding: 18,
      paddingBottom: 4,
    },
  },
  checkbox_img_main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 28,
    marginTop: 4,
    [theme.breakpoints.down("sm")]: {
      gap: 8,
    },
  },
  order_detail_card_heading: {
    fontSize: 22,
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
  order_detail_radio_main: {
    paddingBlock: 12,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      paddingBlock: 6,
    },
  },
  order_detail_radio_label: {
    fontSize: 20,
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
  order_detail_radio_heading: {
    fontSize: 20,
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
  order_detail_checkbox: {
    width: 30,
    height: 30,
    marginRight: 0,
    [theme.breakpoints.down("md")]: {
      width: 20,
      height: 20,
      marginRight: 4,
    },
  },
  order_details_time_feilds: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: "4rem",
    alignItems: "center",
    paddingBottom: 18,
    [theme.breakpoints.down("sm")]: {
      display: "block",
      marginTop: 12,
      "& > div": {
        marginTop: 12,
      },
    },
  },
  //order details page

  address_info_card: {
    padding: 32,
    borderRadius: 24,
    background: "white",
    boxShadow: "0px 0px 8px lightgrey",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: 22,
      display: "flex",
      height: 120,
    },
  },
  address_info_card_content: {
    display: "flex",
    alignItems: "center",
    gap: "2rem",
    [theme.breakpoints.down("sm")]: {
      gap: "1rem",
    },
  },

  // Side page banner

  side_pages_main_heading: {
    color: "#fff",
    fontSize: "60px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "32px",
      textAlign: "center",
    },
  },

  page_banner: {
    position: "relative",
    "& > img": {
      height: "450px",
      width: " 100%",
      objectFit: "cover",
    },
    [theme.breakpoints.down("sm")]: {
      "& > img": {
        height: "200px",
      },
    },
  },
  banner_inner: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#00000057",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  employee_type_img: {
    width: "100%",
    borderRadius: 25,
  },
  order_detail_p: {
    fontSize: "20px",
    width: "150px",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      width: "110px",
    },
  },
  order_detail_type_button: {
    background: theme.palette.dominos.appSecondary,
    color: theme.palette.dominos.sand,
    transition: "1s",
    paddingInline: 30,
    border: "none",
    "&:hover": {
      background: theme.palette.dominos.appSecondary,
      color: theme.palette.dominos.sand,
      transition: "1s",
      border: "none",
      boxShadow:
        "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
    "&:disabled": {
      background: "#AEAEAE",
      color: "white",
    },
  },
  order_detail_type_main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "48px",
    gap: "12px",
  },
}));

export default useStyles;
