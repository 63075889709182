import moment from "moment-timezone";
import { useLocation } from "react-router-dom";
import { global_transalations } from "transalations";

export function addCommas(number: number) {
  let numStr = String(number);
  let [integerPart, decimalPart] = numStr.split(".");
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  let formattedNumber = decimalPart
    ? `${integerPart}.${decimalPart}`
    : integerPart;
  return formattedNumber;
}

export const _secretKey = "D@MIN@S-DO_2035!";

export const cancelled_statuses = ["bad", "abondaned", "void", "bad order"];

export const capitalize_string = (word: string): string => {
  return word?.charAt(0)?.toUpperCase() + word?.slice(1);
};

export const set_localize_content = (obj: any, language: string) => {
  return obj?.[language];
};

export const authenticated_routes = [
  "/sign-up",
  "/phone-verify",
  "/signup-details",
  "/forgot-password",
  "/login",
  "/welcome",
  "/service-method",
  "/otp-verify",
  "/addresses",
  "/add-address",
  "/address-details",
  "/choose-store",
];

export const IsAuthenticatedRoute = () => {
  const location = useLocation();
  return (
    authenticated_routes.includes(location.pathname) ||
    location.pathname.startsWith("/address-details") ||
    location.pathname.startsWith("/add-address")
  );
};

export const getHeightLightTime = (time: any) => {
  const newTime = moment(new Date().setMinutes(new Date().getMinutes() + time));
  const minutes = parseInt(newTime.format("mm"));

  if (minutes >= 0 && minutes <= 5) {
    return "05";
  } else if (minutes >= 5 && minutes <= 10) {
    return "10";
  } else if (minutes >= 10 && minutes <= 15) {
    return "15";
  } else if (minutes >= 15 && minutes <= 20) {
    return "20";
  } else if (minutes >= 20 && minutes <= 25) {
    return "25";
  } else if (minutes >= 25 && minutes <= 30) {
    return "30";
  } else if (minutes >= 30 && minutes <= 35) {
    return "35";
  } else if (minutes >= 35 && minutes <= 40) {
    return "40";
  } else if (minutes >= 40 && minutes <= 45) {
    return "45";
  } else if (minutes >= 45 && minutes <= 50) {
    return "50";
  } else if (minutes >= 50 && minutes <= 55) {
    return "55";
  } else {
    return "00";
  }
};

export const handle_error = (error: any, lng: any) => {
  try {
    if (typeof error === "string") {
      return error;
    } else {
      let err =
        error?.data?.error ??
        error?.error ??
        error?.data?.message ??
        error?.error?.message ??
        error?.message ??
        (set_localize_content(
          global_transalations.something_went_wrong,
          lng
        ) as any);

      if (typeof err === "object") {
        return capitalize_string(err?.[`${lng}`]);
      } else {
        return capitalize_string(err);
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const checkStoreStatus = (
  storeOpenTime: string,
  storeCloseTime: string
) => {
  const format = "HH:mm:ss";
  const timezone = "Asia/Tbilisi";

  const now = moment.tz(timezone);

  const openingTime = moment.tz(storeOpenTime, format, timezone);
  const closingTime = moment.tz(storeCloseTime, format, timezone);

  if (closingTime.isBefore(openingTime)) {
    closingTime.add(1, "day");
  }

  return now.isBetween(openingTime, closingTime);
};

export const is_redirect_store = (store: any) => {
  if (store?.is_redirect) {
    let system_date = moment(new Date())
      .utcOffset("+04:00")
      .format("HHmm") as any;
    let opening_hour = moment(
      `2030-12-31T${store?.redirect_start_date}`
    ).format("HHmm") as any;
    let closing_hour = moment(`2030-12-31T${store?.redirect_end_date}`).format(
      "HHmm"
    ) as any;
    return (
      (opening_hour > closing_hour && //1000 200
        ((system_date >= opening_hour && system_date <= 2359) || //100 >= 1000 && 100 <= 2359
          system_date <= closing_hour)) || //100 <= 200
      (opening_hour <= closing_hour && //1000 2200
        system_date >= opening_hour && //1823 >= 1000
        system_date <= closing_hour) //1823 <= 2200
    );
  }
};

export const replace_url = (url: any) => {
  return url?.replace(
    "https://dominosge.s3.eu-central-1.amazonaws.com/",
    "https://deykvccewcmn1.cloudfront.net/"
  ) as any;
};

export const sanitize_field = (value: any) => {
  if (typeof value === "string") {
    return value.replace(/&/g, "and").replace(/[^\w\s]/g, "");
  }
  return value;
};

export const trim_id = (value: any) => {
  return value?.toString()?.slice(-8);
};

export function calculateDistance(lat1: any, lon1: any, lat2: any, lon2: any) {
  const R = 6371;
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const lat1Rad = toRadians(lat1);
  const lat2Rad = toRadians(lat2);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) *
      Math.sin(dLon / 2) *
      Math.cos(lat1Rad) *
      Math.cos(lat2Rad);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;

  return distance;
}

function toRadians(deg: any) {
  return (deg * Math.PI) / 180;
}

export const aggregator_delivery_prices = [
  {
    km: 1,
    glovo_price: 5.9,
    wolt_price: 5.95,
  },
  {
    km: 2,
    glovo_price: 6.9,
    wolt_price: 6.7,
  },
  {
    km: 3,
    glovo_price: 7.9,
    wolt_price: 7.9,
  },
  {
    km: 4,
    glovo_price: 8.9,
    wolt_price: 9.1,
  },
  {
    km: 5,
    glovo_price: 9.8,
    wolt_price: 10.3,
  },
  {
    km: 6,
    glovo_price: 10.9,
    wolt_price: 11.5,
  },
  {
    km: 7,
    glovo_price: 12.9,
    wolt_price: 12.7,
  },
];
